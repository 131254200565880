<template>
  <div class="table-responsive">
    <b-table v-if="items.length > 0" hover :items="items" :fields="fields">
      <template #cell(status)="row">
        <span
          :class="statusClass(row.value)"
          class="py-1 px-2 rounded-pill text-white font-weight-bolder"
          >{{ row.value }}</span
        >
      </template>
      <template #cell(actions)="row">
        <b-button
          size="sm"
          variant="primary"
          @click="$emit('openModal', row.item)"
        >
          <b-icon icon="eye" />
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: 'protocolo',
          label: 'Protocolo',
          sortDirection: 'asc',
        },
        {
          key: 'titulo',
          label: 'Título',
        },
        {
          key: 'prefeitura',
          label: 'Prefeitura',
        },
        {
          key: 'unidades_compartilhadas',
          label: 'Unidades Compartilhadas',
        },
        {
          key: 'status',
          label: 'Status',
        },
        { key: 'actions', label: 'Ações' },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    statusClass(status) {
      const statusDictionary = {
        encaminhado: 'bg-info',
        recebido: 'bg-success',
        'em análise': 'bg-warning',
        concluído: 'bg-success',
        salvo: 'bg-success',
      };
      return statusDictionary[status.toLowerCase()];
    },
  },
};
</script>
